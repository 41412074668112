import { isAxiosError } from 'axios';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useDeleteMemo from 'hooks/service/mutations/useDeleteMemo';
import useToast from 'hooks/useToast';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MemoFormType } from 'sharedComponents/MemoForm/types';

import { MEMO_TEXT } from './constants';

type Props = {
  onClose: () => void;
};

const DeleteMemoDialog = ({ onClose }: Props) => {
  const { control } = useFormContext<MemoFormType>();
  const id = useWatch({ control, name: 'memo_id' });

  const { setToast } = useToast();

  const { mutate: deleteMemoMutate, isPending } = useDeleteMemo(Number(id));

  const clickDelete = useCallback(() => {
    deleteMemoMutate(undefined, {
      replaceOnError: false,
      onSettled: onClose,
      onSuccess: () => {
        setToast({ type: 'success', message: MEMO_TEXT.successMessage.delete });
      },
      onError: error => {
        const isErrorDrawerCase =
          isAxiosError(error) &&
          (error?.message === 'Network Error' || error?.response?.status === 401 || error?.response?.status === 403);
        if (isErrorDrawerCase) {
          return false;
        }
      },
    });
  }, [deleteMemoMutate, onClose, setToast]);

  return (
    <Dialog
      onClose={onClose}
      negativeAction={{ text: MEMO_TEXT.deleteDialog.negativeText, disabled: isPending }}
      positiveAction={{
        text: MEMO_TEXT.deleteDialog.positiveText,
        disabled: isPending,
        onClick: clickDelete,
      }}>
      <DialogDescription>메모를 삭제하시겠습니까?</DialogDescription>
    </Dialog>
  );
};

export default DeleteMemoDialog;
