import { MbscEventList } from '@mobiscroll/react';
import { useDrag } from '@use-gesture/react';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilState } from 'recoil';
import { currentDateAtom } from 'recoil/schedule';

import { CurrentPeriodTyps } from '../../types';
import getMoveDate from '../../utils/getMoveDate';
import ListTypeAgendaCard from './ListTypeAgendaCard';
import NoEvents from './NoEvents';

type Props = {
  events: MbscEventList[];
  setCurrentPeriod: Dispatch<SetStateAction<CurrentPeriodTyps>>;
};

const ListTypeAgendaList = ({ events, setCurrentPeriod }: Props) => {
  const [currentDate, setCurrentDate] = useRecoilState(currentDateAtom);

  const gestureBind = useDrag(({ last: isLeave, movement: [currentX] }) => {
    if (!isLeave) return;

    if (currentX < -70 || currentX > 70) {
      const moveNumber = 1;
      const currentMove = currentX < -70 ? moveNumber : currentX > 70 ? -moveNumber : 0;
      const nextDate = getMoveDate(currentDate, currentMove);
      setCurrentDate(nextDate);
      setCurrentPeriod({ startDate: nextDate, endDate: nextDate });
    }
  });

  if (!events.length) {
    return (
      <div className="gesture-bind-wrapper" {...gestureBind()}>
        <NoEvents />
      </div>
    );
  }

  const filteredEtc = events[0].events.filter(({ original }) => original?.type === 'AE');
  const remainEvents = events[0].events.filter(({ original }) => original?.type !== 'AE');

  const sortEtc = filteredEtc.sort((a, b) => {
    return dayjs(a.original?.createdAt).valueOf() - dayjs(b.original?.createdAt).valueOf();
  });

  const combineEvents = [...sortEtc, ...remainEvents];

  return (
    <ul className="gesture-bind-wrapper" {...gestureBind()}>
      <li>
        {combineEvents.map(event => (
          <ListTypeAgendaCard key={event.id} event={event} />
        ))}
      </li>
    </ul>
  );
};

export default ListTypeAgendaList;
