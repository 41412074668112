import dayjs from 'dayjs';
import { BookingCommonParams, WeekdayNum } from 'hooks/service/mutations/usePostBookingPrivate';
import filters from 'utils/filters';

import { BookingCommonFormType } from '../types';
import convertRepeatWeekdayNum from './convertRepeatWeekdayNum';

const formatLectureCommonParams = (formValues: BookingCommonFormType): BookingCommonParams => {
  const { title, selectedStaffId, startDate, endDate, startTime, endTime, roomId, repeat, repeatCustomWeekday } = formValues;

  const selectedWeekday = convertRepeatWeekdayNum(repeat, repeatCustomWeekday);
  const start_time = filters.timeSecondZero(startTime);
  const end_time = filters.timeSecondZero(endTime);

  /**
   * 기간 선택하지 않았을 때, weekday 보내지 않음.
   * 기간 선택했는데 반복설정 요일을 따로 선택하지 않았을 때, 선택한 기간의 요일을 weekday 로 보내줘야함
   */
  const weekday =
    startDate === endDate ? undefined : selectedWeekday.length ? selectedWeekday : getWeekdayNumbers(startDate, endDate);

  return {
    title,
    instructor_id: Number(selectedStaffId),
    start_date: startDate,
    end_date: endDate,
    start_time,
    end_time,
    weekday,
    room_id: roomId && !isNaN(Number(roomId)) ? Number(roomId) : undefined,
  };
};

export default formatLectureCommonParams;

export const getWeekdayNumbers = (startDate: string, endDate: string): Array<WeekdayNum> => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const weekdaySet: Set<WeekdayNum> = new Set();

  let current = start;
  while (current.isBefore(end) || current.isSame(end, 'day')) {
    weekdaySet.add(current.day() as WeekdayNum);
    current = current.add(1, 'day');
  }

  return Array.from(weekdaySet).sort((a, b) => a - b);
};
