import { PERMISSION } from 'constants/permission';

export const DEFAULT_PERMISSION_INDEX = 19;

export const PERMISSION_CHECK_LIST = {
  studio: [
    {
      id: PERMISSION.facility.edit.id,
      label: '시설정보 설정',
      description: '상호, 주소, 연락처, 운영시간 등 시설의 정보를 추가 및 수정할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.operation.edit.id,
      label: '운영정보 설정',
      description: '수업의 예약, 취소, 미리 알림을 설정할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.role.edit.id,
      label: '역할별 권한 설정',
      description: '역할별로 접근 권한을 설정할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.room.edit.id,
      label: '룸 관리 설정',
      description: '룸을 추가, 수정, 삭제하는 권한을 설정할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.staff.view.id,
      label: '스태프 조회',
      description: '스튜디오 오너, 관리자, 강사 등 스태프를 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.staff.create.id, label: '스태프 등록' },
        { id: PERMISSION.staff.edit.id, label: '스태프 정보 수정' },
        { id: PERMISSION.staff.delete.id, label: '스태프 삭제' },
      ],
    },
  ],
  member: [
    {
      id: PERMISSION.member.view.id,
      label: '회원 목록 조회',
      description: '전체 회원 목록을 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.member.downloadExcel.id, label: '회원 목록 엑셀 다운로드' },
        { id: PERMISSION.member.create.id, label: '회원 등록' },
        { id: PERMISSION.member.edit.id, label: '회원 정보 수정' },
        { id: PERMISSION.member.viewMobile.id, label: '회원의 휴대폰 번호 보기' },
        { id: PERMISSION.member.delete.id, label: '회원 삭제' },
        { id: PERMISSION.member.registerTicket.id, label: '회원에게 수강권 발급' },
        { id: PERMISSION.member.updateTicket.id, label: '회원의 수강권 정보 조회 및 수정' },
      ],
    },
    {
      id: PERMISSION.member.memo.view.id,
      label: '회원 메모 조회',
      description: '회원에게 등록된 메모를 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.member.memo.create.id, label: '회원 메모 등록' },
        { id: PERMISSION.member.memo.edit.id, label: '회원 메모 수정' },
        { id: PERMISSION.member.memo.delete.id, label: '회원 메모 삭제' },
      ],
    },
    {
      id: PERMISSION.counsel.view.id,
      label: '상담 고객 조회',
      description: '상담 기록, 방문 일정 등을 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.counsel.create.id, label: '상담 고객 등록' },
        { id: PERMISSION.counsel.edit.id, label: '상담 고객 수정' },
        { id: PERMISSION.counsel.delete.id, label: '상담 고객 삭제' },
      ],
    },
  ],
  ticket: [
    {
      id: PERMISSION.sales.view.id,
      label: '매출 열람',
      description: '매출 페이지를 열람할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.ticket.create.id,
      label: '수강권 등록',
      description: '새로운 수강권을 만들 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.ticket.edit.id,
      label: '수강권 수정',
      description: '수강권 정보를 수정할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.ticket.manage.id,
      label: '수강권 판매 정지 및 판매 재개',
      description: '수강권 판매정지를 설정 할 수 있고 판매를 재개할 수 있습니다.',
      details: [],
    },
  ],
  schedule: [
    /** 본인의 기타 일정 */
    {
      id: PERMISSION.etcSchedule.mine.create.id,
      title: '본인의 기타 일정',
      label: '본인의 기타 일정 등록',
      description: '기타 일정을 등록할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.etcSchedule.mine.edit.id,
      label: '본인의 기타 일정 수정',
      description: '기타 일정을 수정할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.etcSchedule.mine.delete.id,
      label: '본인의 기타 일정 삭제',
      description: '기타 일정을 삭제할 수 있습니다. ',
      details: [],
    },
    /** 본인의 그룹 수업 */
    {
      id: PERMISSION.lecture.mine.create.group.id,
      title: '본인의 그룹 수업',
      label: '본인의 그룹 수업 등록',
      description: '그룹 수업을 등록할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.lecture.mine.editLecture.group.id,
      label: '본인의 그룹 수업 수정',
      description: '그룹 수업의 담당 강사, 수업 시간, 최소 수강 인원을 수정할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.lecture.mine.editBookings.group.id,
      label: '본인의 그룹 수업 예약 변경',
      description: '그룹 수업의 회원 예약, 출결 상태를 변경할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.lecture.mine.cancelBookings.group.id,
      label: '본인의 그룹 수업 예약 취소',
      description: '그룹 수업의 회원 예약을 취소할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.lecture.mine.deleteLecture.group.id,
      label: '본인의 그룹 수업 삭제',
      description: '그룹 수업을 삭제할 수 있습니다.',
      details: [],
    },
    /** 본인의 프라이빗 수업 */
    {
      id: PERMISSION.lecture.mine.create.private.id,
      title: '본인의 프라이빗 수업',
      label: '본인의 프라이빗 수업 등록',
      description: '프라이빗 수업을 등록할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.lecture.mine.editLecture.private.id,
      label: '본인의 프라이빗 수업 수정',
      description: '프라이빗 수업의 담당 강사, 수업 시간, 최소 수강 인원을 수정할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.lecture.mine.editBookings.private.id,
      label: '본인의 프라이빗 수업 예약 변경',
      description: '프라이빗 수업의 회원 예약, 출결 상태를 변경할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.lecture.mine.cancelBookings.private.id,
      label: '본인의 프라이빗 수업 예약 취소',
      description: '프라이빗 수업의 회원 예약을 취소할 수 있습니다.',
      details: [],
    },
    {
      id: PERMISSION.lecture.mine.deleteLecture.private.id,
      label: '본인의 프라이빗 수업 삭제',
      description: '프라이빗 수업을 삭제할 수 있습니다.',
      details: [],
    },
    /** 다른 스태프의 그룹 수업 */
    {
      id: PERMISSION.lecture.others.view.group.id,
      title: '다른 스태프의 그룹 수업',
      label: '다른 스태프의 그룹 수업 조회',
      description: '다른 스태프의 그룹 수업을 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.lecture.others.create.group.id, label: '다른 스태프의 그룹 수업 등록' },
        { id: PERMISSION.lecture.others.editLecture.group.id, label: '다른 스태프의 그룹 수업 수정' },
        { id: PERMISSION.lecture.others.editBookings.group.id, label: '다른 스태프의 그룹 수업 예약 변경' },
        { id: PERMISSION.lecture.others.cancelBookings.group.id, label: '다른 스태프의 그룹 수업 예약 취소' },
        { id: PERMISSION.lecture.others.deleteLecture.group.id, label: '다른 스태프의 그룹 수업 삭제' },
      ],
    },
    /** 다른 스태프의 프라이빗 수업 */
    {
      id: PERMISSION.lecture.others.view.private.id,
      title: '다른 스태프의 프라이빗 수업',
      label: '다른 스태프의 프라이빗 수업 조회',
      description: '다른 스태프의 프라이빗 수업을 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.lecture.others.create.private.id, label: '다른 스태프의 프라이빗 수업 등록' },
        { id: PERMISSION.lecture.others.editLecture.private.id, label: '다른 스태프의 프라이빗 수업 수정' },
        { id: PERMISSION.lecture.others.editBookings.private.id, label: '다른 스태프의 프라이빗 수업 예약 변경' },
        { id: PERMISSION.lecture.others.cancelBookings.private.id, label: '다른 스태프의 프라이빗 수업 예약 취소' },
        { id: PERMISSION.lecture.others.deleteLecture.private.id, label: '다른 스태프의 프라이빗 수업 삭제' },
      ],
    },
    /** 일정 메모 */
    {
      id: PERMISSION.lecture.memo.create.id,
      title: '일정 메모',
      label: '메모 등록',
      description: '일정 상세페이지에 메모를 등록할 수 있습니다.',
      details: [
        { id: PERMISSION.lecture.memo.edit.id, label: '메모 수정' },
        { id: PERMISSION.lecture.memo.delete.id, label: '메모 삭제' },
      ],
    },
  ],
  notice: [
    {
      id: PERMISSION.board.notice.view.id,
      label: '공지사항 조회',
      description: '공지사항을 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.board.notice.create.id, label: '공지사항 등록, 수정' },
        { id: PERMISSION.board.notice.delete.id, label: '공지사항 삭제' },
      ],
    },
  ],
  qna: [
    {
      id: PERMISSION.board.qna.view.id,
      label: '1:1 문의 조회',
      description: '1:1 문의를 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.board.qna.comment.id, label: '1:1 문의 댓글 등록, 수정, 삭제' },
        { id: PERMISSION.board.qna.deleteOthers.id, label: '1:1 문의 다른 스태프 댓글 삭제' },
      ],
    },
  ],
  message: [
    {
      id: PERMISSION.message.sms.view.id,
      label: '문자 메시지 조회',
      description: '문자 메시지를 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.message.sms.send.id, label: '문자 메시지 보내기' },
        { id: PERMISSION.message.sms.edit.id, label: '문자 메시지 수정 및 예약 취소' },
        { id: PERMISSION.message.sms.delete.id, label: '문자 메시지 삭제' },
      ],
    },
    {
      id: PERMISSION.message.push.view.id,
      label: '앱 푸시 메시지 조회',
      description: '앱 푸시 메시지를 조회할 수 있습니다.',
      details: [
        { id: PERMISSION.message.push.send.id, label: '앱 푸시 메시지 보내기' },
        { id: PERMISSION.message.push.edit.id, label: '앱 푸시 메시지 수정 및 예약 취소' },
        { id: PERMISSION.message.push.delete.id, label: '앱 푸시 메시지 삭제' },
      ],
    },
  ],
};
