import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

/** 이후 모든 수정 시 종료일을 과거로 선택할 때 노출 */
const ChangePastDialog = () => {
  const { control, setValue, getValues } = useFormContext<BookingCommonFormType>();
  const tempEndDate = useWatch({ control, name: 'tempEndDate' });
  const isSelectedPastDate = useWatch({ control, name: 'isSelectedPastDate' });
  const { pathname } = useLocation();
  const description = pathname.includes('etc') ? 'etc' : 'lecture';

  const close = (shouldDirty?: boolean) => {
    setValue('isSelectedPastDate', false, { shouldDirty });
  };

  const clickCancel = () => {
    setValue('endDate', tempEndDate || getValues('endDate'));
    close();
  };

  const resetRepeat = () => {
    setValue('repeat', null);
    setValue('repeatCustomWeekday', []);
    setValue('tempRepeatCustomWeekday', []);
  };

  const clickContinue = () => {
    close(true);
    const { firstScheduleDate, endDate } = getValues();
    if (firstScheduleDate === endDate) {
      resetRepeat();
    }
  };

  return (
    <>
      {isSelectedPastDate && (
        <Dialog
          onClose={clickCancel}
          positiveAction={{
            text: '계속',
            onClick: clickContinue,
          }}
          negativeAction={{
            text: '취소',
            onClick: clickCancel,
            style: 'outlined',
          }}>
          <DialogDescription tag="pre">{BOOKING_COMMON_FORM_TEXT.endDate[description]}</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default ChangePastDialog;
