import { isAxiosError } from 'axios';
import Button from 'components/Button';
import usePatchMemo from 'hooks/service/mutations/usePatchMemo';
import usePostFileUploadAttachmentPresignedUrl from 'hooks/service/mutations/usePostFileUploadAttachmentPresignedUrl';
import usePostMemo from 'hooks/service/mutations/usePostMemo';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { MEMO_TEXT } from './constants';
import { MemoFormType } from './types';

const SaveButton = () => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = useFormContext<MemoFormType>();
  const memo = useWatch({ control, name: 'memo' });
  const files = useWatch({ control, name: 'files' });
  const imgUrls = useWatch({ control, name: 'imgUrls' });
  const [isMutating, setIsMutating] = useState(false);

  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const { mutate: createMemoMutate, isPending: isCreating } = usePostMemo();
  const { mutate: updateMemoMutate, isPending: isUpdating } = usePatchMemo(Number(getValues('memo_id')));
  const { getAttachmentIds } = usePostFileUploadAttachmentPresignedUrl('memo');
  const disabled = isMutating || isCreating || isUpdating || (!memo && files && !files.length && imgUrls && !imgUrls.length);
  const memoMutate = getValues('memo_id') ? updateMemoMutate : createMemoMutate;

  const saveMemo = useCallback(
    async (values: MemoFormType) => {
      setIsMutating(true);
      const { files, imgUrls, attachments, memo_id, ...rest } = values;
      const newFileIds = await getAttachmentIds(files);
      if (newFileIds === false) return; // 이미지 업로드 실패시 중단

      const existingImageIds =
        attachments?.filter(({ id }) => imgUrls.some(({ id: imgUrlId }) => id === imgUrlId)).map(({ id }) => id) || [];

      const attachment_id = [...existingImageIds, ...newFileIds];

      const params = { ...rest, attachment_id };
      const message = MEMO_TEXT.successMessage[memo_id ? 'update' : 'create'];

      memoMutate(params, {
        replaceOnError: false,
        onSettled: () => {
          setIsMutating(false);
        },
        onSuccess: () => {
          setToast({ type: 'success', message });
          setPopup(null);
        },
        onError: error => {
          const isErrorDrawerCase =
            isAxiosError(error) &&
            (error?.message === 'Network Error' || error?.response?.status === 401 || error?.response?.status === 403);
          if (isErrorDrawerCase) {
            setPopup(null);
          }
        },
      });
    },
    [getAttachmentIds, memoMutate, setPopup, setToast],
  );

  if (!isDirty) {
    return null;
  }

  return (
    <Button textColor="primary" fontSize={15} fontWeight={600} disabled={disabled} onClick={handleSubmit(saveMemo)}>
      저장
    </Button>
  );
};

export default SaveButton;
