import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingFailResponse } from './usePostBookingPrivate';
import { EtcScheduleParams } from './usePostEtcSchedule';

/** 기타 일정 1개 수정 */
const usePatchEtcSchedule = (etcId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<EtcScheduleUpdateParams>({
    method: `patch`,
    url: `/api/etcSchedule/${etcId}`,
  });

  return useCustomMutation<
    AxiosResponse,
    AxiosError<Array<BookingFailResponse> | CustomError>,
    EtcScheduleUpdateParams | undefined,
    unknown
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['etc/schedule', 'detail'],
      });
    },
  });
};

export default usePatchEtcSchedule;

export type EtcScheduleUpdateParams = Omit<
  EtcScheduleParams,
  'start_date' | 'end_date' | 'start_time' | 'end_time' | 'is_repeat' | 'weekday'
> & {
  start_on: string; // 2024-07-29 15:00:00
  end_on: string; // 2024-07-29 15:00:00
};
