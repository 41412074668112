import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import filters from 'utils/filters';

const BookingStartDate = () => {
  const { getValues } = useFormContext<BookingCommonFormType>();

  return (
    <FormFieldLinkButton iconName="dateStart" showArrow={false} disabled>
      {filters.dateWithWeekday(getValues('start'))}
    </FormFieldLinkButton>
  );
};

export default BookingStartDate;
